$(document).ready(function() {
  
  $(".pay_button").on("click", function(e) {
    $(this).css('pointer-events', 'none');
    $(this).html(click_processing);
  });

  $(".one_time_click").on("click", function(e) {
    $(this).css('pointer-events', 'none');
    $(this).html(click_processing);
  });

    $('.toggle_slider').on('click', function() {
        if($(this).parent('.toggle_switch').find('input:checked').length > 0){
            window.cancelsubscription($(this).parent('.toggle_switch').attr('data-order-id'));
        } else {
            window.resumesubscription($(this).parent('.toggle_switch').attr('data-order-id'));
        }
    });

    $('#use_card').change(function () {
        if($(this).val() == '0' && !$('body').find('section.payment_form').hasClass('free_session')){
            
            if($('#save').val() == '1'){
                $('.subscriptions').removeClass('hide');
            } else {
                $('.subscriptions').addClass('hide');
            }
            
            $('#payment_form').removeClass('hide');
            if($('body').find('#arca.active').length > 0){
                $('.card_config').removeClass('hide');
            }
        } else {
            $('#payment_form').addClass('hide');
            if($('body').find('#arca.active').length > 0){
                $('.card_config').addClass('hide');
                if($('#save').val() == '1'){
                    $('.subscriptions').removeClass('hide');
                } else {
                    $('.subscriptions').addClass('hide');
                }
            }
        }
    });

    $('#save').on('change', function(){
        if($(this).val() == '1'){
            $('.subscriptions').removeClass('hide');
        } else {
            $('.subscriptions').addClass('hide');
        }
    });


  $('.option_tick').on('click', function(e){

    $('.option_tick').removeClass('active');
    $(this).addClass('active');
    $('#pay_form').find('#payment_option').val($(this).attr('id'));

    if($(this).attr('id') == 'arca'){
        $('.card_config').removeClass('hide');
        $('.subscriptions').removeClass('hide');
    } else {
        $('.card_config').addClass('hide');
        $('.subscriptions').addClass('hide');
    }

  });

    if($('body').find('#use_card').length > 0){
        $('#use_card').change();
    }

    if($('body').find('#arca.active').length > 0 && $('#payment_form.hide').find('#arca.active').length <= 0){
        $('.card_config').removeClass('hide');
    }

  if(typeof(slider) !== 'undefined'){
    $(window).on('resize', function(){
      
      if(slider.getInfo().pages > 1) {
        $('.tns-outer').addClass('enable_drag_pointer');
      } else {
        $('.tns-outer').removeClass('enable_drag_pointer');
      }
    
    });
    
    $(window).resize();
  
  }


  $('.check_mediation, check_mediation_label').on('click',function(){
      if($(this).prop('checked')){
          $(this).closest('form').find('button.submit_report').removeClass('hide');
      } else {
          $(this).closest('form').find('button.submit_report').addClass('hide');
      }
  });

});

window.deletecard = function(cardId){
  $('#' + cardId).addClass('deleting_card');
	$.ajax({
       url: "/user/profile/delete-card",
       data: {cardId: cardId},
       type: "POST",
       success: function(data) {
           if(data) {
              $('#' + cardId).removeClass('deleting_card');
              $('#' + cardId).addClass('deleted_card');           
           }
       },
       error: function() {
          $('#' + cardId).removeClass('deleting_card');
          console.log('error deleting card');
      }
    });
}

window.setdefaultcard = function(cardId){
  $('#' + cardId).addClass('changing_card_default');
  $.ajax({
       url: "/user/profile/set-default-card",
       data: {cardId: cardId},
       type: "POST",
       success: function(data) {
           if(data) {
              $('.default_source').removeClass('default_source');
              $('#' + cardId).removeClass('changing_card_default');
              $('#' + cardId).addClass('default_source');
           }
       },
       error: function() {
          $('#' + cardId).removeClass('changing_card_default');
          console.log('error setting default card');
      }
    });
}

window.cancelsubscription = function(subscriptionId){
  $.ajax({
       url: "/user/profile/subscriptions",
       data: {subscriptionId: subscriptionId, action: 'cancel'},
       type: "POST",
       success: function(data) {
           if(data) {
                console.log('Subscription canceled.');
           }
       },
       error: function() {
          console.log('error deleting subscription');
      }
    });
}

window.resumesubscription = function(subscriptionId){
  $.ajax({
       url: "/user/profile/subscriptions",
       data: {subscriptionId: subscriptionId, action: 'resume'},
       type: "POST",
       success: function(data) {
           if(data) {
                console.log('Subscription activated.');
           }
       },
       error: function() {
          console.log('error resuming subscription');
      }
    });
}

window.checkformstatus = function(){
      $.ajax({
        type: "POST",
        url: '/user/profile/check-form-status',
        success: function(data){
            if(data == 'true') {
              $('.notification-modal').modal({
                  show: true
              });
              setTimeout("location.reload();", 2000);
            } else {
              console.log('still processing your forms');
            }
        },
        error: function(){
            console.log('error retrieving information about form processing');
        },
    });
}

$('form.ajax_save_form').on('beforeSubmit', function(e) {
    var form = $(this);
    var formData = form.serialize();
    var current_text = $(this).find('button.btn').html();
    $(this).find('button.btn').addClass('ajax_saving');
    $(this).find('button.btn').html('Processing...');
    $.ajax({
        url: form.attr("action"),
        type: form.attr("method"),
        data: formData,
        success: function (data) {
            console.log('success ' + data);
            $('form.ajax_save_form').find('button.ajax_saving').addClass('ajax_saved');
            $('form.ajax_save_form').find('button.ajax_saving').html('Done');
            setTimeout(function(){
                $('form.ajax_save_form').find('button.ajax_saving').html(current_text);
                $('form.ajax_save_form').find('button.ajax_saving').removeClass('ajax_saved');
                $('form.ajax_save_form').find('button.ajax_saving').removeClass('ajax_saving');
            }, 2000);
        },
        error: function () {
            console.log("Something went wrong");
            $('form.ajax_save_form').find('button.ajax_saving').addClass('ajax_error');
            $('form.ajax_save_form').find('button.ajax_saving').html('Failed');
            setTimeout(function(){
                $('form.ajax_save_form').find('button.ajax_saving').html(current_text);
                $('form.ajax_save_form').find('button.ajax_saving').removeClass('ajax_error');
                $('form.ajax_save_form').find('button.ajax_saving').removeClass('ajax_saving');
            }, 3000);
        }
    });
}).on('submit', function(e){
    e.preventDefault();
});



// window.uclicked = function() {
//   // do something
// }


// function uclicked() {
//   // do something
// }
// window.uclicked = uclicked;